import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootswatch/dist/sandstone/bootstrap.min.css';

const App = () => {
  const [text, setText] = useState('');
  const [uppercase, setUppercase] = useState('');
  const [lowercase, setLowercase] = useState('');

  const convert = (e) => {
    setText(e.target.value);
    setUppercase(e.target.value.toUpperCase());
    setLowercase(e.target.value.toLowerCase());
  };

  const notifyLowercase = () => toast(`📎 Copied "${lowercase}".`);
  const notifyUppercase = () => toast(`📎 Copied "${uppercase}".`);

  console.log(lowercase);

  return (
    <>
      <ToastContainer />
      <nav className='navbar navbar-expand-lg navbar-dark bg-primary'>
        <a className='navbar-brand' href='/'>
          Text Converter | hrtn.me
        </a>
      </nav>

      <div className='form-group py-5 m-auto' style={{ width: '400px' }}>
        <input
          className='form-control form-control-lg'
          type='text'
          placeholder='Enter text'
          value={text}
          onChange={(e) => convert(e)}
          id='inputLarge'
        />
      </div>

      {text.length > 0 && (
        <>
          <div className='form-group m-auto' style={{ width: '400px' }}>
            <label class='control-label'>UPPERCASE</label>
            <input
              className='form-control form-control-lg'
              type='text'
              value={uppercase}
              id='inputLarge'
              disabled
            />
            <CopyToClipboard
              text={uppercase}
              // onCopy={() => this.setState({ copied: true })}
              onCopy={notifyUppercase}
            >
              <button type='button' className='btn btn-success my-2'>
                Copy to clipboard
              </button>
            </CopyToClipboard>
          </div>

          <div className='form-group py-4 m-auto' style={{ width: '400px' }}>
            <label class='control-label'>lowercase</label>
            <input
              className='form-control form-control-lg'
              type='text'
              value={lowercase}
              id='inputLarge'
              disabled
            />
            <CopyToClipboard text={lowercase} onCopy={notifyLowercase}>
              <button type='button' className='btn btn-success my-2'>
                Copy to clipboard
              </button>
            </CopyToClipboard>
          </div>
        </>
      )}
    </>
  );
};

export default App;
